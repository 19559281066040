import { emailValidate } from '@/components/Subscr/validation'
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Heading,
  Input,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { SetStateAction, useEffect, useState } from 'react'
import ErrorModal from './ErrorModal'
import SuccessModal from './SuccessModal'
import { handleSubscribe } from './handleSubscribe'

const Subscr = () => {
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure()
  const {
    isOpen: isOpenError,
    onOpen: onOpenError,
    onClose: onCloseError,
  } = useDisclosure()

  const handleChange = (event: {
    target: { value: SetStateAction<string> }
  }) => {
    setEmail(event?.target.value)
  }

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await handleSubscribe(email)

      if (response === 200) {
        onOpenSuccess()
      } else {
        onOpenError()
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      // Handle the error gracefully, e.g., by displaying an error message to the user
      onOpenError()
    } finally {
      setIsLoading(false)
      setEmail('')
    }
  }

  useEffect(() => {
    setIsValid(() => emailValidate(email))
  }, [email])

  return (
    <Box
      bg="#EEF353"
      pt={{ base: '48px', sm: '52px' }}
      pb={{ base: '72px', sm: '52px' }}
    >
      <Container maxW="1120px">
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          direction={{ base: 'column', lg: 'row' }}
          gap={{ base: '32px', lg: '0' }}
        >
          <Box maxW="400px">
            <Heading
              as="h2"
              w={'100%'}
              color={'#000'}
              fontWeight="800"
              fontSize={{ base: '24px', sm: '30px' }}
              lineHeight="100%"
            >
              Join our newsletter
            </Heading>
          </Box>
          <Box w="100%">
            <FormControl isRequired>
              <Flex gap={2} direction={{ base: 'column', sm: 'row' }}>
                <Input
                  w="100%"
                  type="email"
                  border="1px solid #fff"
                  borderRadius="none"
                  minH="60px"
                  bg="#fff"
                  color="#000"
                  placeholder="your e-mail address"
                  value={email}
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  bg="#0A0835"
                  textColor="#fff"
                  fontSize="18px"
                  fontWeight="500"
                  lineHeight="100%"
                  borderRadius="none"
                  w="100%"
                  maxW={{ md: '224px' }}
                  h={{ base: '48px', sm: '60px' }}
                  _hover={{ opacity: 0.8 }}
                  onClick={handleSubmit}
                  isDisabled={!isValid}
                  display="flex"
                  alignItems="center"
                >
                  <Text>Subscribe </Text>
                  {isLoading && (
                    <Spinner
                      display="inline-block"
                      ml={'8px'}
                      thickness="2px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="sm"
                    />
                  )}
                </Button>
              </Flex>
            </FormControl>
            <SuccessModal isOpen={isOpenSuccess} onClose={onCloseSuccess} />
            <ErrorModal isOpen={isOpenError} onClose={onCloseError} />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Subscr
