import { Box } from '@chakra-ui/react'

interface Props {
  width?: string | object
  height?: string | object
  color?: string
}

const NewTwitter = ({
  width = '24px',
  height = 'auto',
  color = '#929292',
}: Props) => {
  return (
    <Box width={width} height={height}>
      <svg
        width="118"
        height="116"
        viewBox="0 0 118 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_807_918)">
          <path
            d="M69.8448 50.7857L113.534 0H103.181L65.2457 44.0965L34.9465 0H0L45.8183 66.6818L0 119.938H10.3536L50.4148 73.3709L82.4129 119.938H117.359L69.8423 50.7857H69.8448ZM55.6641 67.2692L51.0217 60.6292L14.0842 7.79407H29.9868L59.7958 50.4337L64.4381 57.0737L103.186 112.499H87.2837L55.6641 67.2718V67.2692Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_807_918">
            <rect width="117.359" height="120" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export default NewTwitter
