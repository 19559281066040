import { Box } from '@chakra-ui/react'

interface Props {
  width?: string | object
  height?: string | object
  color?: string
}

const Telegram = ({ width = '24px', height = 'auto', color = '#929292' }: Props) => {
  return (
    <Box width={width} height={height}>
      <svg width="135" height="107" viewBox="0 0 135 107" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M116.241 6.90821L16.5094 45.3663C9.70311 48.1001 9.74248 51.897 15.2606 53.5901L40.8656 61.5776L100.108 24.1995C102.909 22.4951 105.469 23.412 103.365 25.2795L55.3669 68.5976H55.3556L55.3669 68.6032L53.6006 94.9957C56.1881 94.9957 57.33 93.8088 58.7812 92.4082L71.2181 80.3145L97.0875 99.4226C101.857 102.049 105.283 100.699 106.47 95.007L123.452 14.9745C125.19 8.00509 120.791 4.84946 116.241 6.90821Z" fill={color} />
      </svg>
    </Box>
  )
}

export default Telegram
