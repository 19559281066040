import { TYPEFORM_ID } from '@/configs'
import * as amplitude from '@amplitude/analytics-browser'
import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { PopupButton } from '@typeform/embed-react'
//import { useEffect, useState } from 'react'
export const buttonStyle: React.CSSProperties = {
  borderRadius: 'none',
  background: 'transparent',
  color: '#fff',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '146%',
  padding: '15px',
  width: '100%',
  maxWidth: '264px',
  border: '1px solid #fff',
  /* Additional custom styles */
  textTransform: 'lowercase',
  letterSpacing: '1px',
}

const Banner = () => {
  amplitude.track('website_visited')
  // const words = ['angel investors', 'founders', 'venture firms'] // Add more words if needed
  // const [currentWordIndex, setCurrentWordIndex] = useState(0)
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length)
  //   }, 2000) // Change word every 2 seconds

  //   return () => clearInterval(interval)
  // }, [])

  return (
    <Box
      position="relative"
      bg="#0A0835"
      pt={{ base: '80px', md: '132px' }}
      pb={{ base: '282px', lg: '141px' }}
      transform="translateY(-1px)"
    >
      <Image
        src="/images/banner-lines.svg"
        alt="Logo"
        w={{ base: '80%', sm: '320px', lg: '35%' }}
        position={{ base: 'absolute' }}
        bottom="0"
        left="0"
      />
      <Container maxW="1120px">
        <Flex direction={'column'} alignItems={{ base: 'flex-end' }} gap="24px">
          <Heading
            as="h1"
            color={'#fff'}
            fontWeight="800"
            fontSize={{ base: '56px', md: '78px' }}
            lineHeight={{ base: '108%', md: '78px' }}
            textAlign={{ base: 'left' }}
            display="inline-flex"
            flexWrap="wrap"
            w="100%"
            maxW={{ base: '100%', lg: '640px' }}
          >
            <Text
              as="span"
              display={{ base: 'flex' }}
              flexDirection={{ base: 'column', lg: 'row' }}
              justifyContent={{ base: 'flex-end' }}
              whiteSpace={{ base: 'nowrap' }}
              transform={{ base: 'none', lg: 'translateX(-360px)' }}
            >
              <Text as="span">{`Venture's`}</Text>
              <Text display="flex">
                <Image
                  src="/images/banner-arrow.svg"
                  alt="Logo"
                  w={{ base: '208px', md: '320px' }}
                  ml={{ base: '-130px', sm: '-75px', lg: '31px' }}
                  mr={{ base: '20px' }}
                />
                financial
                {/* <motion.span  
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  transition={{ duration: 0.5 }}
                >
                  {words[currentWordIndex]}
                </motion.span> */}
              </Text>
            </Text>
            <Text as="span">operating system </Text>
          </Heading>
          <Flex
            direction={'column'}
            alignItems={{ base: 'flex-start' }}
            gap="40px"
            maxW={{ base: '100%', lg: '640px' }}
          >
            <Text
              color="#CACAE1"
              fontWeight="400"
              fontSize={{ base: '22px', md: '26px' }}
              lineHeight="146%"
            >
              Joiny provides infrastructure for crypto native investments, EVM
              based financial agreements (e.g. SAFE), and multi party funds
              management.
            </Text>
            <PopupButton id={TYPEFORM_ID} style={buttonStyle}>
              Get early access
            </PopupButton>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Banner
