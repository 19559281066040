'use client'
import Apps from '@/components/Apps'
import Banner from '@/components/Banner'
import FAQ from '@/components/FAQ'
import Features from '@/components/Features'
import Header from '@/components/Header'
import Join from '@/components/Join'
// import People from '@/components/People'
import Reward from '@/components/Reward'
import Setting from '@/components/Setting'
import Subscr from '@/components/Subscr'
import Footer from '@/components/TheFooter'
import type { NextPage } from 'next'

const IndexPage: NextPage = () => {
  return (
    <>
      <Header />
      <Banner />
      <Setting />
      <Features />
      <Subscr />
      <Apps />
      <Reward />
      <FAQ />
      <Join />
      {/* <Bucket /> */}
      <Footer />
    </>
  )
}

export default IndexPage
