import { useEffect, useRef } from 'react'

interface VideoProps {
  source: string
}

export function Video({ source }: VideoProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    const videoElement = videoRef.current
    if (videoElement) {
      videoElement.playbackRate = 2 // Set the desired playback speed
    }
  }, [])

  return (
    <video
      width="auto"
      height="auto"
      preload="auto"
      autoPlay
      muted
      loop
      playsInline
      src={source}
      ref={videoRef}
      onLoadedMetadata={() => {
        if (videoRef.current) {
          videoRef.current.playbackRate = 2
        }
      }}
    >
      <track
        src="/path/to/captions.vtt"
        kind="subtitles"
        srcLang="en"
        label="English"
      />
      Your browser does not support the video tag.
    </video>
  )
}
