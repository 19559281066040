import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { Video } from '../../app/video'

const CARDS = [
  {
    title: 'Fundraise',
    description:
      'Joiny provides powerful tools to fundraise from LPs, Investment DAOs, or Angels, giving shareholders more transperancy, control, and accountability.',
    active: false,
  },
  {
    title: 'Invest',
    description:
      'The protocol has novel financial agreement frameworks, that allows structuring investment terms like never before.',
    active: true,
  },
  {
    title: 'Manage & Track',
    description:
      'Track portfolio companies from one place, and participate in their decision making as a MultiSig or DAO party.',
    active: false,
  },
]

const Setting = () => {
  return (
    <Box
      position="relative"
      bg="#F4F4F4"
      pt={{ base: '56px', md: '118px' }}
      pb={{ base: '72px', md: '124px' }}
    >
      <Container maxW="1120px">
        <Flex direction={'column'} gap={{ base: '32px', md: '48px' }}>
          <Flex
            direction={'column'}
            alignItems={'flex-start'}
            gap={{ base: '16px', md: '32px' }}
          >
            <Heading
              as="h2"
              color={'#0A0835'}
              fontWeight="800"
              fontSize={{ base: '48px', md: '78px' }}
              lineHeight="100%"
              textAlign={{ base: 'left', lg: 'center' }}
              display="inline-flex"
              flexWrap="wrap"
            >
              Embrace the new class of investment Smart Contracts.
            </Heading>
            <Text
              maxW="843px"
              color="#696969"
              fontWeight="400"
              fontSize={{ base: '20px' }}
              lineHeight="146%"
            >
              Leveraging ZK Proof Systems, battle tested Governance Mechanisms,
              and trusted Oracles, Joiny opens novel ways for fundraising and
              allocating funds without the need for intermediaries.
            </Text>
          </Flex>
          <Flex gap={'16px'} direction={{ base: 'column', md: 'row' }}>
            {CARDS.map((card, i) => (
              <Box
                key={i}
                w="100%"
                minH="130px"
                borderRadius={'none'}
                bg="#fff"
                color="#000"
                fontWeight={400}
                fontSize={{ base: '14px' }}
                lineHeight={{ base: '20px' }}
                overflow="hidden"
                p={{ base: '24px' }}
                border="7px solid #fff"
                transition="0.5s"
                _hover={{ border: '7px solid #EEF353', transition: '0.5s' }}
              >
                <Text
                  position="relative"
                  mb="15px"
                  fontWeight={300}
                  fontSize={{ base: '24px' }}
                  lineHeight={{ base: '116%' }}
                >
                  <Image
                    zIndex="1"
                    src="/images/figure.svg"
                    alt="Logo"
                    position="absolute"
                    bottom="2px"
                    left="-5px"
                  />
                  <Text
                    as="span"
                    position="relative"
                    zIndex="1"
                    display="block"
                    fontWeight="700"
                  >
                    {`0${i + 1}`}.
                  </Text>
                </Text>
                <Text
                  fontWeight={700}
                  fontSize={{ base: '24px' }}
                  lineHeight={{ base: '116%' }}
                  color="inherit"
                >
                  {card.title}
                </Text>
                <Text
                  fontWeight={400}
                  fontSize={{ base: '16px' }}
                  lineHeight={{ base: '146%' }}
                  mt="8px"
                  color="inherit"
                >
                  {card.description}
                </Text>
              </Box>
            ))}
          </Flex>
          <Box
            w="100%"
            borderRadius={'none'}
            bg="#EEF353"
            color="#000"
            lineHeight={{ base: '20px' }}
            overflow="hidden"
            border="7px solid #fff"
            transition="0.5s"
            _hover={{ border: '7px solid #EEF353', transition: '0.5s' }}
          >
            {Video({ source: '/images/video_joiny.mp4' })}
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Setting
