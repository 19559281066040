import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react'

const ITEMS = [
  {
    title: 'Is Joiny an onchain DocuSign?',
    text: 'Joiny offers more than just signature collection. Yt provides comprehensive services including escrowing purchase amounts, facilitating the transfer of equity tokens, enforcing contract terms, and much more.',
  },
  {
    title: 'Is Joiny just for investors?',
    text: 'No, Joiny isn`t exclusively for investors. In fact, investors can encourage their investee organizations to create an account at Joiny to get full benefits of the infrastructure.',
  },
  {
    title: 'What types of investment contracts does joiny have?',
    text: 'Joiny offers a diverse range of investment contracts to cater to various needs. Presently, our platform supports agreements such as SAFE, SAFT, Stock Purchase, Subscription, Stock Option, RSUs, and other types of investment contracts. Our goal is to simplify and standardize on-chain agreements, ensuring that all parties involved feel protected and confident when making deals.',
  },
  {
    title: 'How on-chain SAFEs work?',
    text: 'When parties agree on the terms of a SAFE (whether it is capped, discounted, or MFN) through Joiny, the platform facilitates the settlement of purchase amounts and generates a separate contract to track subsequent fundraising outcomes. Once these results are finalized, the SAFE contract amount seamlessly converts into equity shares, ensuring a transparent and efficient process for all involved parties.',
  },
  // {
  //   title:
  //     'how about other typical clauses of investment contracts that cannot be tracked on-chain?',
  //   text: 'when parties agree to on-chain contract terms, they also implicitly agree to general investment terms that encompass defined jurisdiction and other pertinent clauses. although our aim is simplicity, our platform provides a robust framework for tracking and enforcing agreements, thus promoting transparency and trust in investment transactions',
  // },
  {
    title: 'How governance terms work on Joiny?',
    text: 'Joiny offers MultiSig and DAO type voting to make collaborative decisions within recepients account. DAO voting works through equity snapshots at a given time.',
  },
  {
    title: 'What else does the Joiny offer?',
    text: 'Your Joiny account has a number of features that will make company, treasury, and equity management simple and comprehensive at the same time. try yourself!',
  },
  // {
  //   title: 'what about privacy?',
  //   text: 'all data on the ethereum (EVM) chains is not private and is immutable, thus not all privacy rights can be exercised.',
  // },
]

const FAQ = () => {
  return (
    <Box
      id="landing-faq"
      position="relative"
      bg="#F4F4F4"
      pt={{ base: '56px', md: '80px' }}
      pb={{ base: '72px', md: '130px' }}
    >
      <Container maxW="1120px">
        <Flex
          direction="column"
          alignItems="center"
          mb={{ base: '12px', md: '24px', lg: '36px' }}
        >
          <Heading
            as="h2"
            color={'#000'}
            fontWeight="800"
            fontSize={{ base: '48px', md: '64px', lg: '72px' }}
            lineHeight="100%"
            textAlign={{ base: 'left', lg: 'center' }}
            w="100%"
            display="inline-flex"
            flexWrap="wrap"
          >
            have questions? look at our faqs:
          </Heading>
        </Flex>
        <Stack
          direction={'column'}
          maxW="680px"
          fontSize={{ base: 'md', sm: 'lg', md: 'lg' }}
          textAlign={'left'}
          w="100%"
          zIndex="2"
          position="relative"
        >
          <Accordion allowToggle>
            <Stack
              px={{ base: '16px', sm: '40px' }}
              pt={{ base: '0', sm: '10px' }}
              pb={{ base: '0' }}
              bg={'#fff'}
              borderRadius="4px"
            >
              {ITEMS.map((item, i) => {
                return (
                  <AccordionItem
                    key={i}
                    borderTop="0"
                    borderBottom={'1px solid #6564DB'}
                    mt="0 !important"
                    _last={{
                      borderBottom: 'none',
                    }}
                  >
                    <AccordionButton
                      py={{ base: '16px', sm: '24px' }}
                      px="0"
                      _hover={{ bg: 'transparent' }}
                    >
                      <Heading
                        flex="1"
                        as="h3"
                        textAlign="left"
                        color={'#000'}
                        fontWeight="500"
                        fontSize={{ base: '16px', md: '20px' }}
                        lineHeight="146%"
                      >
                        {item.title}
                      </Heading>
                      <AccordionIcon fontSize="25px" color="#AEAAAA" />
                    </AccordionButton>
                    <AccordionPanel
                      color={'#000'}
                      fontWeight="350"
                      fontSize={{ base: '16px' }}
                      lineHeight="25px"
                      px="0"
                      pt="0"
                    >
                      {item.text}
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Stack>
          </Accordion>
        </Stack>
        <Image
          display={{ base: 'none', md: 'block' }}
          zIndex="1"
          src="/images/faq-lines.svg"
          alt="Lines"
          position="absolute"
          bottom="0"
          right="0"
        />
      </Container>
    </Box>
  )
}

export default FAQ
