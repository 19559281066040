import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useColorMode,
  type ModalProps,
} from '@chakra-ui/react'
import type { FC } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

interface IModalBasic extends ModalProps {
  onClose: () => void
  isOpen: boolean
  children: JSX.Element | JSX.Element[]
  title?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controls?: any
  autoHeightMax?: string
  maxW?: string
  w?: string | { [key: string]: string }
  h?: string
  padding?: string
  isCentered?: boolean
  borderRadius?: string
}

const ModalBasic: FC<IModalBasic> = (props) => {
  const {
    children,
    title,
    controls,
    autoHeightMax = '100vh',
    maxW = '540px',
    w = '100%',
    padding = { base: '20px', md: '40px' },
    borderRadius = '16px',
  } = props

  const { colorMode } = useColorMode()

  return (
    <Modal blockScrollOnMount={false} {...props}>
      <ModalOverlay
        w="100%"
        bg="rgba(0, 0, 0, 0.3)"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent
        w={w}
        maxW={maxW}
        borderRadius={borderRadius}
        mx="16px"
        bg={colorMode === 'dark' ? '#1F1E20' : '#fff'}
      >
        <ModalCloseButton
          mt={{ base: '0', md: '-48px' }}
          bg={colorMode === 'dark' ? '#1F1E20' : '#fff'}
          mr={{ base: '0', md: '-12px' }}
          _hover={{
            bg: colorMode === 'dark' ? '#1F1E20' : '#fff',
          }}
          _active={{
            bg: colorMode === 'dark' ? '#1F1E20' : '#fff',
          }}
          zIndex="10"
        />
        <ModalBody w={w} p={padding}>
          {title}
          <Scrollbars autoHeight autoHeightMax={autoHeightMax}>
            <Box p="1px">{children}</Box>
          </Scrollbars>
          {controls}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalBasic
