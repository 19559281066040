import JoinyLogo from '@/components/common/Logo'
import { scrollToBlock } from '@/components/utils/scrollToBlock'
import { TYPEFORM_ID } from '@/configs'
import { Box, Container, Flex, Link, useMediaQuery } from '@chakra-ui/react'
import { PopupButton } from '@typeform/embed-react'
// import { Item, Menu } from 'burger-menu'
// import 'burger-menu/lib/index.css'

const buttonStyle: React.CSSProperties = {
  borderRadius: 'none',
  background: '#fff',
  color: '#000',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '20px',
  padding: '12px',
}

const MENU = [
  {
    name: 'features',
    scrollToSection: '#landing-features',
  },
  {
    name: 'faq',
    scrollToSection: '#landing-faq',
  },
  {
    name: 'join us',
    scrollToSection: '#landing-join-us',
  },
]

const Header = () => {
  // const [isOpen, setIsOpen] = useState(false)
  const [isLessThan768] = useMediaQuery('(max-width: 768px)')

  // function clickByBurgerMenu(id: string) {
  //   scrollToBlock(id)
  // }

  return (
    <Flex py={{ base: '10px' }} bg="#0A0835" zIndex="10" className="header">
      <Container maxW="1360px">
        <Flex alignItems={'center'}>
          <Box pr="15px" mr={{ base: 'auto' }} cursor="pointer">
            <JoinyLogo
              mini={isLessThan768}
              height={(isLessThan768 && '34px') || '50px'}
            />
          </Box>
          <Flex
            display={{ base: 'none', md: 'flex' }}
            gap={{ base: '48px' }}
            mr={{ base: '48px' }}
          >
            {MENU.map((item, i) => (
              <Link
                key={i}
                color="#CACAE1"
                fontSize="18px"
                onClick={() => scrollToBlock(item.scrollToSection)}
              >
                {item.name}
              </Link>
            ))}
          </Flex>
          <PopupButton id={TYPEFORM_ID} style={buttonStyle}>
            get started
          </PopupButton>
          {/* <Box
            display={{ base: 'block', md: 'none' }}
            ml="18px"
            cursor={'pointer'}
            onClick={() => setIsOpen(true)}
          >
            <Image src="/images/burger.svg" alt="Burger" />
          </Box> */}
        </Flex>
      </Container>
      {/* <Menu
        className="burger-menu"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {MENU.map((item, i) => (
          <Item
            key={`burger_item_${i}`}
            itemKey={`burger_item_${i}`}
            text={item.name}
            onClick={() => clickByBurgerMenu(item.scrollToSection)}
          />
        ))}
      </Menu> */}
    </Flex>
  )
}

export default Header
