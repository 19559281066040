import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react'

const CARDS_1 = [
  {
    title: 'Governance Term',
    description:
      'As a part of a financial contract, Investors may request MultiSig or DAO type voting power.',
    icon: '/images/Assets.svg',
    iconWrapHeight: '132px',
    bg: '#3DAFC2',
  },
  {
    title: 'DEFI protocols',
    description:
      'An account may be connected to popular DEFI protocols for swapping, staking, and earning.',
    icon: '/images/defi2.svg',
    iconWrapHeight: '157px',
    bg: '#000',
  },
]

const CARDS_2 = [
  {
    title: 'Treasury management',
    description:
      'Joiny has all necessary tools for effective funds management, including equity based payouts to shareholders, tracking and managing expenses, and using analytics tools.',
    icon: '/images/treasury.svg',

    iconWrapHeight: '186px',

    bg: '#FF007A',
  },
]

const CARDS_3 = [
  {
    title: 'Stealth & Omni chain wallets',
    description:
      'The protocol can create privacy based sub-wallets, or wallets on other chains that can be controlled from your main account.',
    icon: '/images/uil_wallet.svg',
    iconWrapHeight: '233px',
    bg: '#00A3FF',
  },
  {
    title: 'Modular Access Control',
    description:
      'Manage different levels of Access Control of your account. Each member can be granted different access levels, as signature and viewing rights.',
    icon: '/images/Member.svg',
    iconWrapHeight: '233px',
    bg: '#1AAB9B',
  },
]

interface ICard {
  title: string
  description: string
  icon: string
  iconWrapHeight: string
  bg: string
  iconPosition?: string
  iconWrapWidth?: string
}

const Card = (card: ICard, i: number) => (
  <Flex
    key={i}
    w="100%"
    minH="130px"
    borderRadius={'none'}
    color="#000"
    fontWeight={400}
    fontSize={{ base: '14px' }}
    lineHeight={{ base: '20px' }}
    overflow="hidden"
    position={'relative'}
    direction={{ base: 'column', md: card?.iconPosition ? 'row' : 'column' }}
    bg={card.bg}
  >
    <Box
      position="relative"
      minW={{ base: '124px', md: '124px' }}
      minH={{ base: '62px', md: '62px' }}
      order={{ base: 1, md: card?.iconPosition === 'right' ? 2 : 1 }}
    >
      {/* <Image
        src={card.icon}
        alt="Logo"
        position={'absolute'}
        left="50%"
        top="50%"
        transform={'translate(-50%, -50%)'}
      /> */}
    </Box>
    <Flex
      order="1"
      direction={'column'}
      p={{ base: '24px' }}
      h="100%"
      w="100%"
      justifyContent={card?.iconPosition ? 'center' : 'flex-start'}
    >
      <Text
        fontWeight={700}
        fontSize={{ base: '24px' }}
        lineHeight={{ base: '116%' }}
        color="#fff"
      >
        {card.title}
      </Text>
      <Text
        fontWeight={400}
        fontSize={{ base: '16px' }}
        lineHeight={{ base: '146%' }}
        mt="8px"
        color="#fff"
      >
        {card.description}
      </Text>
    </Flex>
  </Flex>
)

const Apps = () => {
  return (
    <Box
      position="relative"
      bg="#F4F4F4"
      pt={{ base: '56px', md: '96px' }}
      pb={{ base: '72px', md: '124px' }}
    >
      <Container maxW="1120px">
        <Flex direction={'column'}>
          <Flex direction={'column'} alignItems={'flex-start'}>
            <Heading
              as="h2"
              color={'#0A0835'}
              fontWeight="800"
              fontSize={{ base: '48px', md: '72px' }}
              lineHeight={{ base: '80%' }}
              textAlign={{ base: 'left', lg: 'center' }}
              display="inline-flex"
              flexWrap="wrap"
            >
              Joiny can do more...
            </Heading>
            <Text
              mt="16px"
              color="#000"
              fontWeight="400"
              fontSize={{ base: '16px' }}
              lineHeight="146%"
            >
              Your account is superpowered with a number of features that makes
              fundraising & investing more secure and efficient
            </Text>
          </Flex>
          <Flex
            direction={{ base: 'column', xl: 'row' }}
            mt={{ base: '24px', md: '40px' }}
            gap={'25px'}
          >
            <Flex
              direction={{ base: 'column', md: 'row', xl: 'column' }}
              gap={'25px'}
              maxW={{ base: '100%', xl: '294px' }}
            >
              {CARDS_1.map((card, i) => Card(card, i))}
            </Flex>
            <Flex gap={'25px'} direction="column">
              {CARDS_2.map((card, i) => Card(card, i))}
              <Flex gap={'25px'} direction={{ base: 'column', md: 'row' }}>
                {CARDS_3.map((card, i) => Card(card, i))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Apps
