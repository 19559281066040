import ModalBasic from '@/components/common/ModalBasic'
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { FC } from 'react'

interface ISuccessModal {
  isOpen: boolean
  onClose: () => void
}

const SuccessModal: FC<ISuccessModal> = ({ isOpen, onClose }) => {
  const successImage = './images/subscribe-form.svg'
  return (
    <ModalBasic
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
      isCentered
      maxW="307px"
      padding="10px 24px 24px 24px"
    >
      <Flex direction="column">
        <Image mx="auto" h="122px" w="100%" src={successImage} />
        <Box>
          <Text mt="8px" fontSize="30px" fontWeight="800" lineHeight="116%">
            Great!
          </Text>
          <Text mt="4px" fontSize="16px" fontWeight="400" lineHeight="146%">
            You have successfully subscribed to the newsletter.
          </Text>
        </Box>
        <Button mt="38px" w="100%" onClick={onClose}>
          Continue
        </Button>
      </Flex>
    </ModalBasic>
  )
}

export default SuccessModal
