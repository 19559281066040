import FigureIcon from '@/components/common/Icons/Figure'
import { scrollToBlock } from '@/components/utils/scrollToBlock'
import { TYPEFORM_ID } from '@/configs'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react'
import { PopupButton } from '@typeform/embed-react'
import { buttonStyle } from '../Banner'

const Features = () => {
  return (
    <Box
      id="landing-features"
      position="relative"
      bg="#0A0835"
      pt={{ base: '70px', md: '96px' }}
      pb={{ base: '72px', md: '124px' }}
      zIndex={'1'}
    >
      <Container position="relative" maxW="1140px">
        <Flex direction="column" alignItems={'flex-start'}>
          <Heading
            w={'100%'}
            maxW={{ base: '483px', lg: '100%' }}
            fontWeight="800"
            fontSize={{ base: '48px', lg: '64px' }}
            lineHeight={{ base: '108%' }}
            color="white"
            position={'relative'}
            textAlign={{ base: 'left' }}
            mb={{ base: '40px', lg: '96px' }}
          >
            <Text position={'relative'} zIndex="2">
              NextGen Contract Frameworks
            </Text>
            <Box
              position={'absolute'}
              top={{ base: '-20px', md: '-5px' }}
              left={{ base: '-10px' }}
              zIndex={'1'}
              w={{ base: '78px' }}
            >
              <FigureIcon width={'100px'} color={'#6564DB'} />
            </Box>
          </Heading>

          {/* Feature 1 */}
          <Flex
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={{ base: '24px', lg: '20px' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Flex
              w={'100%'}
              maxW="425px"
              direction={'column'}
              alignItems={'flex-start'}
            >
              <Text
                color="#fff"
                fontWeight={'800'}
                fontSize={'30px'}
                lineHeight={'116%'}
                mb={'16px'}
                textAlign="left"
              >
                Performance Unlocks
              </Text>
              <Text
                textAlign={'left'}
                color="#fff"
                fontWeight={'400'}
                fontSize={'16px'}
                lineHeight={'146%'}
                mb={'24px'}
              >
                Set on/off chain performance indicators to unlock your
                investments or tokens. Our Oracles and Proofs systems will make
                sure that they are met.
              </Text>
              <Button
                variant={'link'}
                position={'relative'}
                color="#fff"
                fontWeight={'400'}
                fontSize={'16px'}
                lineHeight={'146%'}
                _hover={{ textDecoration: 'none' }}
                rightIcon={<ChevronDownIcon boxSize={6} />}
                onClick={() => scrollToBlock('#landing-faq')}
                borderRadius="none"
              >
                <Text borderBottom="1px solid #fff">
                  Learn more how Performance Contracts work
                </Text>
              </Button>
            </Flex>
            <Image src="/images/Performance.svg" alt="performances" />
          </Flex>
          {/* Feature 1 */}
          <Flex
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mt={{ base: '56px', lg: '100px' }}
            gap={{ base: '24px', lg: '20px' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Flex
              w={'100%'}
              maxW="425px"
              direction={'column'}
              alignItems={'flex-start'}
            >
              <Text
                color="#fff"
                fontWeight={'800'}
                fontSize={'30px'}
                lineHeight={'116%'}
                mb={'16px'}
                textAlign="left"
              >
                Onchain SAFEs and SAFTs
              </Text>
              <Text
                textAlign={'left'}
                color="#fff"
                fontWeight={'400'}
                fontSize={'16px'}
                lineHeight={'146%'}
                mb={'24px'}
              >
                Use onchain versions of popular investing mechanisms such as
                SAFEs and SAFTs. The Protocol tracks investment events and
                seamlessly converts investments into equity.
              </Text>
              <Button
                variant={'link'}
                position={'relative'}
                color="#fff"
                fontWeight={'400'}
                fontSize={'16px'}
                lineHeight={'146%'}
                _hover={{ textDecoration: 'none' }}
                rightIcon={<ChevronDownIcon boxSize={6} />}
                onClick={() => scrollToBlock('#landing-faq')}
                borderRadius="none"
              >
                <Text borderBottom="1px solid #fff">
                  How onchain SAFEs work?
                </Text>
              </Button>
            </Flex>
            <Image src="/images/SAFE.svg" alt="safes" />
          </Flex>
          {/* Feature 2 */}
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            gap={{ base: '24px', lg: '20px' }}
            mt={{ base: '56px', lg: '100px' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Flex
              w={'100%'}
              maxW="425px"
              direction={'column'}
              alignItems={'flex-start'}
            >
              <Text
                color="#fff"
                fontWeight={'800'}
                fontSize={'30px'}
                lineHeight={'116%'}
                mb={'16px'}
                textAlign="left"
              >
                Stock options & many more
              </Text>
              <Text
                textAlign={'left'}
                color="#fff"
                fontWeight={'400'}
                fontSize={'16px'}
                lineHeight={'146%'}
                mb={'24px'}
              >
                Diverse onchain financial contract frameworks will allow for
                setting up options, buy backs, vesting, joining governance, and
                others, in a transparent and straightforward ways.
              </Text>
            </Flex>
            <Image src="/images/share_updated2.svg" alt="options" />
          </Flex>
          {/* Feature 3 */}
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            gap={{ base: '24px', lg: '20px' }}
            mt={{ base: '56px', lg: '100px' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Flex
              w="100%"
              maxW="425px"
              direction={'column'}
              alignItems={'flex-start'}
            >
              <Text
                color="#fff"
                fontWeight={'800'}
                fontSize={'30px'}
                lineHeight={'116%'}
                mb={'16px'}
                textAlign="left"
              >
                Fundraising contracts
              </Text>
              <Text
                textAlign={'left'}
                color="#fff"
                fontWeight={'400'}
                fontSize={'16px'}
                lineHeight={'146%'}
                mb={{ base: '14px', lg: '20px' }}
              >
                Whitelist your qualified investors, and setup a fundraising
                campaign with preferred terms. Got an oversubsribed round? How
                about auctioning your equity shares?!
              </Text>
              <PopupButton id={TYPEFORM_ID} style={buttonStyle}>
                Request a custom contract
              </PopupButton>
            </Flex>
            <Image src="/images/fundraising.svg" alt="fundraising" />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Features
