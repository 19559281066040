import ModalBasic from '@/components/common/ModalBasic'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

interface IErrorModal {
  isOpen: boolean
  onClose: () => void
}

const ErrorModal: FC<IErrorModal> = ({ isOpen, onClose }) => {
  return (
    <ModalBasic
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
      isCentered
      maxW="307px"
      padding="24px"
    >
      <Flex direction="column">
        <Box>
          <Text mt="8px" fontSize="30px" fontWeight="800" lineHeight="116%">
            Something went wrong
          </Text>
          <Text mt="4px" fontSize="16px" fontWeight="400" lineHeight="146%">
            There were some problems.
            <br />
            Please try again
          </Text>
        </Box>

        <Button bg="#D0D0D0" mt="38px" w="100%" onClick={onClose}>Close</Button>
      </Flex>
    </ModalBasic>
  )
}

export default ErrorModal
