import Figure from '@/components/common/Icons/Figure'
import Twitter from '@/components/common/Icons/Twitter'
import { TWITTER_LINK, TYPEFORM_ID } from '@/configs'
import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react'
import { PopupButton } from '@typeform/embed-react'
import { buttonStyle } from '../Banner'

const Reward = () => {
  return (
    <Box
      position="relative"
      bg="#0A0835"
      pt={{ base: '56px', md: '96px' }}
      pb={{ base: '72px', md: '96px' }}
    >
      <Container maxW="1120px">
        <Flex direction={'column'}>
          <Flex
            direction={'column'}
            alignItems={'flex-start'}
            gap={{ base: '16px', md: '19px' }}
          >
            <Heading
              as="h2"
              color={'#fff'}
              fontWeight="800"
              fontSize={{ base: '48px', md: '64px' }}
              lineHeight="108%"
              textAlign={{ base: 'left' }}
              display="inline-flex"
              flexWrap="wrap"
              maxW={{ base: '100%' }}
            >
              Loved our product?
              <br />
              <Box position={'relative'}>
                <Text zIndex="1" position="relative">
                  Become an early beta user.
                </Text>
                <Box
                  transform={'rotate(-40deg)'}
                  zIndex="0"
                  position={'absolute'}
                  top="0"
                  right="-12px"
                  w={{ base: '0', md: '78px' }}
                >
                  <Figure color="#4A99E9" width={'100%'} />
                </Box>
              </Box>
            </Heading>
            <Text
              color="#CACAE1"
              fontWeight="400"
              fontSize={{ base: '20px' }}
              lineHeight="146%"
            >
              We are willing to work with your individually to meet your
              specific needs.
            </Text>
          </Flex>
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            gap={'25px'}
            mt={{ base: '24px', md: '48px' }}
            mb={{ base: '24px', md: '48px' }}
          >
            <Box
              position={'relative'}
              w="100%"
              maxW={{ base: '100%', lg: '294px' }}
              p="24px"
              bg="#fff"
              overflow={'hidden'}
            >
              <Box
                w="24px"
                h="24px"
                bg="#EEF353"
                borderRadius={'5px'}
                position="absolute"
                top="-1px"
                left="-1px"
              />
              <Text
                fontWeight="400"
                fontSize={{ base: '16px' }}
                lineHeight="146%"
                color={'#000'}
              >
                Our team can develop custom investment contracts that fits your
                workflow and needs.
              </Text>
            </Box>
            <Flex
              direction={'column'}
              w="100%"
              p="24px"
              pb={{ base: '160px', md: '24px' }}
              bg="#fff"
              position={'relative'}
              overflow="hidden"
            >
              <Flex direction={'column'} gap="16px" zIndex={1}>
                <Heading
                  as="h3"
                  color={'#000'}
                  fontWeight="700"
                  fontSize={{ base: '24px' }}
                  lineHeight="116%"
                  textAlign={{ base: 'left' }}
                >
                  Help us,spreading the word about Joiny:
                </Heading>
                <Text
                  fontWeight="400"
                  fontSize={{ base: '16px' }}
                  lineHeight="146%"
                  color={'#000'}
                >
                  follow us on X{' '}
                  <Link color="#6564DB" href={TWITTER_LINK} isExternal>
                    @joiny_xyz
                  </Link>
                </Text>
                <Text
                  fontWeight="400"
                  fontSize={{ base: '16px' }}
                  lineHeight="146%"
                  color={'#000'}
                >
                  Repost{' '}
                  <Link
                    color="#6564DB"
                    href={'https://x.com/joiny_xyz/status/1776494492952183283'}
                    isExternal
                  >
                    this tweet
                  </Link>
                </Text>
                <Text
                  fontWeight="400"
                  fontSize={{ base: '16px' }}
                  lineHeight="146%"
                  color={'#000'}
                >
                  or share your experience how Joiny is helpful to you.
                </Text>
              </Flex>
              <Grid
                gap="15px"
                position={'absolute'}
                top={{ base: 'auto', md: '-5px' }}
                bottom={{ base: '-20px', md: 'auto' }}
                right={{ base: '-10px' }}
                gridTemplateColumns={{
                  base: 'repeat(4, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                gridTemplateRows={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                gridTemplateAreas={{
                  base: `
                    'one two three four'
                    'five six seven eight'
                  `,
                  md: `
                    'two one four'
                    '. five three'
                    '. . seven'
                  `,
                }}
                zIndex={'0'}
              >
                <Box gridArea="one">
                  <Twitter width={'76px'} color="#4A99E9" />
                </Box>
                <Box gridArea="two">
                  <Twitter width={'76px'} color="#EEF353" />
                </Box>
                <Box gridArea="three">
                  <Twitter width={'76px'} color="#4A99E9" />
                </Box>
                <Box gridArea="four">
                  <Twitter width={'76px'} color="#EEF353" />
                </Box>
                <Box gridArea="five">
                  <Twitter width={'76px'} color="#EEF353" />
                </Box>
                <Box gridArea="six" display={{ base: 'block', md: 'none' }}>
                  <Twitter width={'76px'} color="#4A99E9" />
                </Box>
                <Box gridArea="seven">
                  <Twitter width={'76px'} color="#EEF353" />
                </Box>
                <Box gridArea="eight" display={{ base: 'block', md: 'none' }}>
                  <Twitter width={'76px'} color="#4A99E9" />
                </Box>
              </Grid>
            </Flex>
          </Flex>
          <PopupButton id={TYPEFORM_ID} style={buttonStyle}>
            Get an early access
          </PopupButton>
        </Flex>
      </Container>
    </Box>
  )
}

export default Reward
