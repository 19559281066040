import { Box } from '@chakra-ui/react'

interface Props {
  width?: string | object
  height?: string | object
  color?: string
}

const Twitter = ({ width = '24px', height = 'auto', color = '#929292' }: Props) => {
  return (
    <Box width={width} height={height}>
      <svg height="100%" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29 2.92258C27.9141 3.41024 26.7622 3.73035 25.5828 3.87224C26.8113 3.12583 27.7546 1.94386 28.1988 0.535554C27.0309 1.23808 25.7531 1.73306 24.4207 1.9991C23.3354 0.826999 21.7892 0.0944824 20.0779 0.0944824C16.792 0.0944824 14.128 2.79488 14.128 6.12549C14.128 6.59825 14.1807 7.0585 14.282 7.50003C9.33732 7.24843 4.95334 4.8474 2.01878 1.19848C1.50675 2.08924 1.21336 3.12537 1.21336 4.23052C1.21336 6.323 2.26381 8.16893 3.86017 9.25054C2.91535 9.22054 1.99131 8.96186 1.16521 8.49609C1.16487 8.52135 1.16487 8.54662 1.16487 8.572C1.16487 11.4941 3.21571 13.9318 5.93741 14.4859C5.06128 14.7274 4.14228 14.7628 3.25061 14.5892C4.00766 16.9853 6.20498 18.7289 8.80841 18.7777C6.77218 20.3953 4.2067 21.3595 1.4193 21.3595C0.938988 21.3595 0.465473 21.3309 0 21.2752C2.633 22.9865 5.76035 23.9849 9.12027 23.9849C20.064 23.9849 26.0483 14.7948 26.0483 6.82493C26.0483 6.56334 26.0427 6.30325 26.0311 6.04465C27.1959 5.19108 28.2013 4.13385 29 2.92258Z"
          fill={color}
        />
      </svg>
    </Box>
  )
}

export default Twitter
