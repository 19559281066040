import { Box } from '@chakra-ui/react'
import React from 'react'

interface IProps {
  mini?: boolean
  height?: string | object
  color?: string
}

const DesctopLogo = ({ color = '#fff' }: { color: string }) => (
  <svg height="100%" viewBox="0 0 82 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.366 7.90983C35.1949 7.73871 34.9628 7.64258 34.7208 7.64258L21.0459 7.64259V7.64662L10.4071 7.64663C5.28099 7.64663 1.12549 11.8021 1.12549 16.9282L1.12559 16.9813L3.6681 14.4388C4.02103 14.0859 4.59204 14.082 4.94972 14.4301L7.57099 16.9813L7.57103 16.1547C7.57103 15.0156 8.49447 14.0922 9.6336 14.0922L21.0874 14.0922V14.0881L34.7136 14.0881C34.9599 14.0881 35.1957 13.9886 35.3675 13.8121L37.65 11.4668C37.9981 11.1091 37.9943 10.5381 37.6413 10.1851L35.366 7.90983Z"
      fill="#6564DB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3195 3.09039C14.1261 3.28379 14.0174 3.5461 14.0174 3.81961L14.0166 21.2173C14.0166 22.3564 13.0931 23.2799 11.954 23.2799H9.63358C8.49446 23.2799 7.57101 22.3564 7.57101 21.2173V19.6586H1.12549V20.0571C1.12549 25.3967 5.45413 29.7254 10.7938 29.7254C16.1334 29.7254 20.4621 25.3967 20.4621 20.0571L20.463 3.82782C20.463 3.54947 20.3504 3.28293 20.1509 3.08878L17.7433 0.745548C17.4401 0.450463 16.9561 0.453744 16.6569 0.752912L14.3195 3.09039Z"
      fill={color}
    />
    <path
      d="M1.12549 19.4542C1.1255 19.2122 1.22163 18.9801 1.39275 18.809L3.66806 16.5337C4.02099 16.1808 4.592 16.1769 4.94968 16.525L7.29498 18.8076C7.47148 18.9793 7.57104 19.2152 7.57104 19.4615L7.57104 20.4091L1.12549 20.4091L1.12549 19.4542Z"
      fill={color}
    />
    <path
      d="M29.8599 30.1057C28.3978 30.1057 27.0982 29.8079 25.961 29.2122C24.8346 28.6057 23.9466 27.7772 23.2967 26.7267C22.6578 25.6653 22.3383 24.4523 22.3383 23.0877C22.3383 21.7231 22.6578 20.5101 23.2967 19.4487C23.9466 18.3765 24.8346 17.5318 25.961 16.9144C27.0982 16.2971 28.3978 15.9884 29.8599 15.9884C31.322 15.9884 32.6108 16.2971 33.7263 16.9144C34.8418 17.5318 35.7137 18.3765 36.3418 19.4487C36.97 20.5101 37.2841 21.7231 37.2841 23.0877C37.2841 24.4523 36.97 25.6653 36.3418 26.7267C35.7137 27.7772 34.8418 28.6057 33.7263 29.2122C32.6108 29.8079 31.322 30.1057 29.8599 30.1057ZM29.8599 26.0444C30.4231 26.0444 30.9104 25.9198 31.322 25.6707C31.7335 25.4216 32.053 25.0696 32.2805 24.6148C32.5079 24.1599 32.6216 23.6455 32.6216 23.0715C32.6216 22.4866 32.5079 21.9668 32.2805 21.5119C32.053 21.057 31.7335 20.6996 31.322 20.4397C30.9104 20.1798 30.4231 20.0498 29.8599 20.0498C29.2967 20.0498 28.8039 20.1798 28.3816 20.4397C27.97 20.6996 27.6451 21.057 27.4068 21.5119C27.1794 21.9668 27.0657 22.4866 27.0657 23.0715C27.0657 23.6455 27.1794 24.1599 27.4068 24.6148C27.6451 25.0696 27.97 25.4216 28.3816 25.6707C28.8039 25.9198 29.2967 26.0444 29.8599 26.0444Z"
      fill={color}
    />
    <path
      d="M40.0166 29.7158V16.3783H44.9552V29.7158H40.0166ZM42.5509 13.6329C41.7711 13.6329 41.1104 13.3621 40.5689 12.8206C40.0274 12.2682 39.7567 11.613 39.7567 10.8549C39.7567 10.0968 40.0274 9.44154 40.5689 8.8892C41.1213 8.32602 41.7819 8.04443 42.5509 8.04443C43.0599 8.04443 43.5256 8.1744 43.948 8.43432C44.3704 8.68342 44.7115 9.01916 44.9714 9.44154C45.2314 9.86392 45.3613 10.335 45.3613 10.8549C45.3613 11.613 45.0852 12.2682 44.5328 12.8206C43.9805 13.3621 43.3198 13.6329 42.5509 13.6329Z"
      fill={color}
    />
    <path
      d="M57.5363 15.9884C58.4677 15.9884 59.3774 16.178 60.2655 16.557C61.1536 16.9361 61.8846 17.5372 62.4586 18.3603C63.0326 19.1726 63.3196 20.2448 63.3196 21.5769V29.7158H58.3648V22.3891C58.3648 21.4361 58.1373 20.7213 57.6825 20.2448C57.2276 19.7574 56.6319 19.5137 55.8955 19.5137C55.4081 19.5137 54.9478 19.6437 54.5146 19.9036C54.0922 20.1527 53.7457 20.5101 53.4749 20.9758C53.215 21.4307 53.085 21.9559 53.085 22.5516V29.7158H48.1464V16.3783H53.085V18.344C53.215 17.9866 53.4857 17.6292 53.8973 17.2718C54.3197 16.9036 54.8449 16.6004 55.4731 16.3621C56.1121 16.113 56.7998 15.9884 57.5363 15.9884Z"
      fill={color}
    />
    <path
      d="M72.9151 25.4433L76.5216 16.3783H81.3627L72.2003 36.4739H67.2617L70.3483 29.7158L64.7761 16.3783H69.6985L72.9151 25.4433Z"
      fill={color}
    />
  </svg>
)

const MobileLogo = ({ color = '#fff' }: { color: string }) => (
  <svg height="100%" viewBox="0 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6391 15.8135H11.2033C9.87543 15.8135 8.79899 16.89 8.79899 18.2178V21.2779H1.28552V19.1194C1.28552 13.1441 6.12952 8.30005 12.1049 8.30005H27.6391V15.8135Z"
      fill="#6564DB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6655 2.98884C16.4401 3.21429 16.3134 3.52006 16.3134 3.83888L16.3124 24.1191C16.3124 25.4469 15.236 26.5234 13.9081 26.5234H11.2033C9.87542 26.5234 8.79897 25.4469 8.79897 24.1191V22.3022H1.28552V22.7667C1.28552 28.991 6.33136 34.0368 12.5557 34.0368C18.7801 34.0368 23.8259 28.991 23.8259 22.7667L23.8269 3.84846C23.8269 3.52398 23.6957 3.21328 23.4632 2.98697L20.6567 0.255495C20.3032 -0.0884804 19.739 -0.0846558 19.3903 0.26408L16.6655 2.98884Z"
      fill={color}
    />
    <path
      d="M1.28562 19.1812L4.24944 16.2174C4.66085 15.806 5.32647 15.8015 5.74341 16.2073L8.79907 19.1812L8.79906 21.9028L1.2856 21.7525L1.28562 19.1812Z"
      fill="#0A0835"
    />
    <path
      d="M1.28562 22.0638C1.28562 21.7817 1.39769 21.5112 1.59716 21.3117L4.24945 18.6594C4.66086 18.248 5.32647 18.2435 5.74342 18.6493L8.4773 21.31C8.68304 21.5103 8.7991 21.7852 8.7991 22.0723L8.7991 23.1769L1.28561 23.1768L1.28562 22.0638Z"
      fill={color}
    />
    <path
      d="M28.1904 8.29502C28.4725 8.29502 28.743 8.40709 28.9425 8.60656L31.5948 11.2589C32.0062 11.6703 32.0107 12.3359 31.6049 12.7528L28.9441 15.4867C28.7439 15.6924 28.469 15.8085 28.1819 15.8085L27.0773 15.8085L27.0773 8.29501L28.1904 8.29502Z"
      fill="#6564DB"
    />
  </svg>
)

const Logo: React.FC<IProps> = ({ mini = false, height = '50px', color = '#fff' }) => {
  return <Box height={height}>{(mini && MobileLogo({ color })) || DesctopLogo({ color })}</Box>
}

export default Logo
