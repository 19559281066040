import Github from '@/components/common/Icons/Github'
import Twitter from '@/components/common/Icons/Twitter'
import { GITHUB_LINK, TWITTER_LINK } from '@/configs'
import * as amplitude from '@amplitude/analytics-browser'
import {
  Box,
  Link as ChakraLink,
  Container,
  Stack,
  Text,
} from '@chakra-ui/react'
import { createElement } from 'react'

const SOCIAL_LINKS = [
  {
    component: Twitter,
    link: TWITTER_LINK,
  },
  {
    component: Github,
    link: GITHUB_LINK,
  },
]
const YEAR = new Date().getFullYear()

const Footer = () => {
  // const {
  //   isOpen: isOpenDisclaimer,
  //   onOpen: onOpenDisclaimer,
  //   onClose: onCloseDisclaimer,
  // } = useDisclosure()

  // function handleOpenDisclaimer() {
  //   amplitude.track('disclaimer_open_clicked')
  //   onOpenDisclaimer()
  // }

  return (
    <Box bg={'#0A0835'} width={'100%'} py="35px">
      <Container
        flex="1"
        as={Stack}
        maxW="1120px"
        direction={{ base: 'column', xl: 'row' }}
        spacing={4}
        justify={{ base: 'center', xl: 'space-between' }}
        align={{ base: 'center', xl: 'center' }}
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          align={{ base: 'center', lg: 'center' }}
          spacing={{ base: '16px', lg: '40px' }}
          mb={{ base: '24px', xl: '0' }}
          textAlign="center"
        >
          <Text
            fontWeight="500"
            fontSize={{ base: '14px', lg: '16px' }}
            lineHeight="146%"
            color="#929292"
          >
            © {YEAR} Joiny, all rights reserved.
          </Text>

          {/* <ChakraLink
            as={Link}
            href={PRIVACY_PAGE}
            passHref
            fontWeight="500"
            fontSize={{ base: '14px', lg: '16px' }}
            lineHeight="146%"
            color="#929292"
            variant="link"
            target="_blank"
          >
            privacy Notice
          </ChakraLink>
          <ChakraLink
            as={Link}
            href={TOS_PAGE}
            passHref
            fontWeight="500"
            fontSize={{ base: '14px', lg: '16px' }}
            lineHeight="146%"
            color="#929292"
            variant="link"
            target="_blank"
          >
            Terms of Services
          </ChakraLink>
          <Button
            onClick={handleOpenDisclaimer}
            fontWeight="500"
            fontSize={{ base: '14px', lg: '16px' }}
            lineHeight="146%"
            color="#929292"
            variant="link"
          >
            Joiny Disclaimer
          </Button> */}
        </Stack>
        <Stack direction={'row'} spacing={{ base: '24px' }}>
          {SOCIAL_LINKS.map((social, i) => (
            <ChakraLink
              href={social.link}
              isExternal
              key={i}
              onClick={() => amplitude.track('footer_social_clicked')}
            >
              {createElement(social.component, {
                height: { base: '24px' },
              })}
            </ChakraLink>
          ))}
        </Stack>
      </Container>

      {/* <ModalDisclaimer onClose={onCloseDisclaimer} isOpen={isOpenDisclaimer} /> */}
    </Box>
  )
}

export default Footer
