import Github from '@/components/common/Icons/Github'
import NewTwitter from '@/components/common/Icons/NewTwitter'
import Telegram from '@/components/common/Icons/Telegram'
import { GITHUB_LINK, TELEGRAM_LINK, TWITTER_LINK } from '@/configs'
import * as amplitude from '@amplitude/analytics-browser'
import { Box, Container, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { createElement, useState } from 'react'

const CARDS = [
  {
    component: Telegram,
    link: TELEGRAM_LINK,
    title: 'telegram',
    description: 'join our gram community',
    bg: { base: '#F4F4F4' },
    hovered: '#4A99E9',
  },
  {
    component: NewTwitter,
    link: TWITTER_LINK,
    title: 'X',
    description: 'all the latest news and updates from joiny',
    bg: { base: '#F4F4F4' },
    hovered: '#000',
  },
  {
    component: Github,
    link: GITHUB_LINK,
    title: 'github',
    description: 'coming soon...',
    bg: { base: '#F4F4F4' },
    hovered: '#0D1116',
  },
]

const Join = () => {
  const [state, setState] = useState<{
    hovered: boolean
    index: number | null
  }>({ hovered: false, index: null })
  return (
    <Box
      id="landing-join-us"
      position="relative"
      bg="#fff"
      pt={{ base: '56px', md: '96px' }}
      pb={{ base: '96px', md: '158px' }}
    >
      <Container maxW="1120px">
        <Flex direction={'column'}>
          <Flex direction={'column'} alignItems={'flex-start'}>
            <Heading
              as="h2"
              color={'#0A0835'}
              fontWeight="800"
              fontSize={{ base: '48px', md: '72px' }}
              lineHeight={{ base: '108%', md: '100%' }}
              textAlign={{ base: 'left', lg: 'center' }}
              display="inline-flex"
              flexWrap="wrap"
              maxW={{ base: '125px', sm: 'none' }}
            >
              Join us
            </Heading>
            <Text
              mt={{ base: '8px', md: '13px' }}
              color="#000"
              fontWeight="400"
              fontSize={{ base: '20px' }}
              lineHeight="146%"
            >
              Want to keep in touch? find us here:
            </Text>
          </Flex>
          <Flex
            gap={'16px'}
            mt={{ base: '24px', md: '48px' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            {CARDS.map((social, i) => (
              <Link
                href={social.link}
                onClick={() => {
                  amplitude.track(`social_clicked_${social.title}`)
                }}
                isExternal
                key={i}
                w="100%"
                minH="130px"
                borderRadius={'none'}
                bg={social.bg}
                color={{ base: '#000' }}
                fontWeight={400}
                fontSize={{ base: '14px' }}
                lineHeight={{ base: '20px' }}
                overflow="hidden"
                position={'relative'}
                p={{ base: '24px' }}
                transition="all 0.3s ease"
                _hover={{
                  textDecoration: 'none',
                  bg: social.hovered,
                  color: '#fff',
                }}
                onMouseEnter={() => setState({ hovered: true, index: i })}
                onMouseLeave={() => setState({ hovered: false, index: i })}
              >
                <Box
                  position={'absolute'}
                  bottom={'-10px'}
                  right={'-10px'}
                  opacity={0.7}
                >
                  {createElement(social.component, {
                    width: { base: '125px' },
                    color:
                      state.hovered && state.index === i ? '#fff' : '#E6E6E6',
                  })}
                </Box>
                <Text
                  fontWeight={700}
                  fontSize={{ base: '24px' }}
                  lineHeight={{ base: '116%' }}
                  color="inherit"
                >
                  {social.title}
                </Text>
                <Text
                  fontWeight={400}
                  fontSize={{ base: '16px' }}
                  lineHeight={{ base: '146%' }}
                  mt="8px"
                  maxW={'180px'}
                  color="inherit"
                >
                  {social.description}
                </Text>
              </Link>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Join
