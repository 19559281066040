import { SENDPULSE_ADDRESS_BOOK } from '@/configs'

export const handleSubscribe = async (email: string) => {
  try {
    const response = await fetch('/api/sendPulse', {
      body: JSON.stringify({ email, addressBook: SENDPULSE_ADDRESS_BOOK }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })

    // Check if the response is successful (status code 2xx)
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`)
    }
    return response.status
  } catch (error) {
    // Handle any errors that occur during the fetch or processing of the response
    console.error('Error in handleSubscribe:', error)
    throw error // Rethrow the error for the caller to handle if necessary
  }
}
