import { Box } from '@chakra-ui/react'

interface Props {
  width?: string | object
  height?: string | object
  color?: string
}

const Figure = ({ width = '24px', height = 'auto', color = '#929292' }: Props) => {
  return (
    <Box as="span" width={width} height={height}>
      <svg height="100%" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.97072e-06 17.4935C6.97072e-06 9.29065 6.64979 2.6409 14.8527 2.64095L26.5603 2.64101V32.3462L14.8525 32.3461C6.6497 32.3461 6.97072e-06 25.6964 6.97072e-06 17.4935Z"
          fill={color}
        />
        <path
          d="M27.5485 2.61536C28.0146 2.61333 28.4622 2.79757 28.7917 3.12713L41.8559 16.1914C42.5383 16.8737 42.5383 17.9801 41.8559 18.6625L28.656 31.8624C28.3302 32.1882 27.8889 32.3722 27.4281 32.3742L24.4275 32.3873L24.5568 2.62841L27.5485 2.61536Z"
          fill={color}
        />
      </svg>
    </Box>
  )
}

export default Figure
